import React from 'react'

import PropTypes from 'prop-types'

import './team11.css'

const Team11 = (props) => {
  return (
    <div className="thq-section-padding team11-team8">
      <div className="team11-max-width thq-section-max-width">
        <div className="team11-section-title">
          <div className="team11-content">
            <h2 className="thq-heading-2">{props.heading1}</h2>
            <p className="thq-body-large team11-text01">{props.content2}</p>
          </div>
        </div>
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="team11-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="team11-slider-slide swiper-slide"
            >
              <div className="team11-card">
                <img
                  alt={props.member1Alt}
                  src={props.member1Src}
                  className="team11-placeholder-image thq-img-ratio-4-3"
                />
                <div className="team11-content01">
                  <div className="team11-title">
                    <span className="team11-text02 thq-body-small">
                      {props.member1}
                    </span>
                    <span className="team11-text03 thq-body-small">
                      {props.member1Job}
                    </span>
                  </div>
                  <span className="team11-text04 thq-body-small">
                    {props.member1Content}
                  </span>
                </div>
              </div>
              <div className="team11-card01">
                <img
                  alt={props.member2Alt}
                  src={props.member2Src}
                  className="team11-placeholder-image01 thq-img-ratio-4-3"
                />
                <div className="team11-content02">
                  <div className="team11-title01">
                    <span className="team11-text05 thq-body-small">
                      {props.member2}
                    </span>
                    <span className="team11-text06 thq-body-small">
                      {props.member2Job}
                    </span>
                  </div>
                  <span className="team11-text07 thq-body-small">
                    {props.member2Content}
                  </span>
                </div>
                <div className="team11-social-icons"></div>
              </div>
              <div className="team11-card02">
                <img
                  alt={props.member3Alt}
                  src={props.member3Src}
                  className="team11-placeholder-image02 thq-img-ratio-4-3"
                />
                <div className="team11-content03">
                  <div className="team11-title02">
                    <span className="team11-text08 thq-body-small">
                      {props.member3}
                    </span>
                    <span className="team11-text09 thq-body-small">
                      {props.member3Job}
                    </span>
                  </div>
                  <span className="team11-text10 thq-body-small">
                    {props.member3Content}
                  </span>
                </div>
                <div className="team11-social-icons01"></div>
              </div>
            </div>
            
          </div>
          <div
            data-thq="slider-pagination"
            className="team11-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
        
      </div>
    </div>
  )
}

Team11.defaultProps = {
  member4Alt1: 'Member Alt',
  member1: 'John Smith',
  member5Alt: 'Image of David Brown, Events Coordinator',
  member2Job: 'Youth Pastor',
  member61: 'Full name',
  member6Src:
    'https://images.unsplash.com/photo-1614151816675-6b707b9b9292?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1M3w&ixlib=rb-4.0.3&q=80&w=1400',
  member4Src1:
    'https://images.unsplash.com/photo-1553654057-870acfcfabd1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1NHw&ixlib=rb-4.0.3&q=80&w=1400',
  member1Alt: 'Image of John Smith, Lead Pastor',
  member5Content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
  content2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  member2Content:
    'Emily is passionate about working with young people and guiding them on their spiritual journey.',
  member1Job: 'Lead Pastor',
  member5Alt1: 'Member Alt',
  member5Src1:
    'https://images.unsplash.com/photo-1626448877021-88bc0c053728?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1M3w&ixlib=rb-4.0.3&q=80&w=1400',
  member6Job1: 'Job title',
  member4Content:
    'Sarah is dedicated to serving the community and organizing outreach programs to make a positive impact.',
  member21: 'Full name',
  member2Content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
  member1Src:
    'https://images.unsplash.com/photo-1614283233556-f35b0c801ef1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1Mnw&ixlib=rb-4.0.3&q=80&w=1400',
  member6Alt1: 'Member Alt',
  member31: 'Full name',
  member6Content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
  actionContent: 'Open positions',
  member6Alt: 'Image of Jessica Miller, Volunteer Coordinator',
  heading2: 'We’re hiring!',
  member4Job: 'Community Outreach Coordinator',
  member1Content:
    'John has been serving as the lead pastor for over a decade, bringing a message of hope and love to our congregation.',
  member4: 'Sarah Lee',
  member4Src:
    'https://images.unsplash.com/photo-1516914943479-89db7d9ae7f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1NXw&ixlib=rb-4.0.3&q=80&w=1400',
  member2Src1:
    'https://images.unsplash.com/photo-1534694895098-4b226f923532?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1NHw&ixlib=rb-4.0.3&q=80&w=1080',
  member5Content:
    'David plans and coordinates various events to foster community engagement and connection.',
  heading1: 'Meet Our Team',
  member6Content:
    'Jessica oversees our volunteer program, matching volunteers with opportunities that align with their skills and interests.',
  member2Src:
    'https://images.unsplash.com/photo-1519205196298-7fc29cb73b3a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1NHw&ixlib=rb-4.0.3&q=80&w=1400',
  member2: 'Emily Johnson',
  member2Alt1: 'Member Alt',
  member2Alt: 'Image of Emily Johnson, Youth Pastor',
  content3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  member3Content:
    'Michael leads our worship team with creativity and enthusiasm, creating a vibrant worship experience.',
  content1: 'Join our team and make a difference in your career!',
  member4Content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
  member6: 'Jessica Miller',
  member51: 'Full name',
  member5Job: 'Events Coordinator',
  member5Job1: 'Job title',
  member5: 'David Brown',
  member3Job1: 'Job title',
  member4Alt: 'Image of Sarah Lee, Community Outreach Coordinator',
  member4Job1: 'Job title',
  member6Job: 'Volunteer Coordinator',
  member3Src:
    'https://images.unsplash.com/photo-1611244420182-82cc3323eadc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1M3w&ixlib=rb-4.0.3&q=80&w=1400',
  member5Src:
    'https://images.unsplash.com/photo-1536321115970-5dfa13356211?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1Mnw&ixlib=rb-4.0.3&q=80&w=1400',
  member3Alt1: 'Member Alt',
  member3Src1:
    'https://images.unsplash.com/photo-1510706019500-d23a509eecd4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1M3w&ixlib=rb-4.0.3&q=80&w=1400',
  member3Content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.',
  member2Job1: 'Job title',
  member3: 'Michael Davis',
  member6Src1:
    'https://images.unsplash.com/photo-1609708993734-29d07306bdc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgzNzE1Mnw&ixlib=rb-4.0.3&q=80&w=1400',
  member3Job: 'Music Director',
  member41: 'Full name',
  member3Alt: 'Image of Michael Davis, Music Director',
}

Team11.propTypes = {
  member4Alt1: PropTypes.string,
  member1: PropTypes.string,
  member5Alt: PropTypes.string,
  member2Job: PropTypes.string,
  member61: PropTypes.string,
  member6Src: PropTypes.string,
  member4Src1: PropTypes.string,
  member1Alt: PropTypes.string,
  member5Content1: PropTypes.string,
  content2: PropTypes.string,
  member2Content: PropTypes.string,
  member1Job: PropTypes.string,
  member5Alt1: PropTypes.string,
  member5Src1: PropTypes.string,
  member6Job1: PropTypes.string,
  member4Content: PropTypes.string,
  member21: PropTypes.string,
  member2Content1: PropTypes.string,
  member1Src: PropTypes.string,
  member6Alt1: PropTypes.string,
  member31: PropTypes.string,
  member6Content1: PropTypes.string,
  actionContent: PropTypes.string,
  member6Alt: PropTypes.string,
  heading2: PropTypes.string,
  member4Job: PropTypes.string,
  member1Content: PropTypes.string,
  member4: PropTypes.string,
  member4Src: PropTypes.string,
  member2Src1: PropTypes.string,
  member5Content: PropTypes.string,
  heading1: PropTypes.string,
  member6Content: PropTypes.string,
  member2Src: PropTypes.string,
  member2: PropTypes.string,
  member2Alt1: PropTypes.string,
  member2Alt: PropTypes.string,
  content3: PropTypes.string,
  member3Content: PropTypes.string,
  content1: PropTypes.string,
  member4Content1: PropTypes.string,
  member6: PropTypes.string,
  member51: PropTypes.string,
  member5Job: PropTypes.string,
  member5Job1: PropTypes.string,
  member5: PropTypes.string,
  member3Job1: PropTypes.string,
  member4Alt: PropTypes.string,
  member4Job1: PropTypes.string,
  member6Job: PropTypes.string,
  member3Src: PropTypes.string,
  member5Src: PropTypes.string,
  member3Alt1: PropTypes.string,
  member3Src1: PropTypes.string,
  member3Content1: PropTypes.string,
  member2Job1: PropTypes.string,
  member3: PropTypes.string,
  member6Src1: PropTypes.string,
  member3Job: PropTypes.string,
  member41: PropTypes.string,
  member3Alt: PropTypes.string,
}

export default Team11
