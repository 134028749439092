import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './navbar.css';
import logo from '../images/logo.png';

const Navbar = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMenuClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="navbar-container">
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <img alt={props.logoAlt} src={props.logoSrc} className="navbar-image1" />
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links">
            {props.navLinks.map((navl, index) => (
              <a href={navl.linkTo} key={index}>
                <span className="thq-link thq-body-small">{navl.name}</span>
              </a>
            ))}
          </nav>
          <div className="navbar-buttons">
            <button className="navbar-action1 thq-button-animated thq-button-filled">
              <span className="thq-body-small">Login</span>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu" onClick={handleBurgerMenuClick}>
          <svg viewBox="0 0 1024 1024" className="navbar-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className={`navbar-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="navbar-nav">
            <div className="navbar-top">
              <img alt={props.logoAlt} src={props.logoSrc} className="navbar-logo" />
              <div data-thq="thq-close-menu" className="navbar-close-menu" onClick={handleCloseMenuClick}>
                <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links1">
              {props.navLinks.map((navl, index) => (
                <a href={navl.linkTo} key={index}>
                  <span className="thq-link thq-body-small">{navl.name}</span>
                </a>
              ))}
            </nav>
          </div>
          <div className="navbar-buttons1">
            <button className="thq-button-filled">Login</button>
          </div>
        </div>
      </header>
    </header>
  );
};

Navbar.defaultProps = {
  navLinks: [
    { name: 'Home', linkTo: '/' },
    { name: 'About Us', linkTo: '#about' },
    { name: 'Events', linkTo: '#event' },
    { name: 'Contact Us', linkTo: '#contact' },
  ],
  logoSrc: logo,
  logoAlt: 'Church Logo',
};

Navbar.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      linkTo: PropTypes.string.isRequired,
    })
  ).isRequired,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
};

export default Navbar;
