import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero.css'

import img1 from '../images/image-1.jpg'

import img2 from '../images/image-2.jpg'
import img3 from '../images/image-3.jpg'
import img4 from '../images/image-4.jpg'
import img5 from '../images/image-5.jpg'
import img6 from '../images/image-6.jpg'
import img7 from '../images/image-7.jpg'
import img8 from '../images/image-8.jpg'
import img9 from '../images/image-9.jpg'
import img10 from '../images/image-10.jpg'
import cross from '../images/cross.jpg'


const Hero = (props) => {
  return (
    <div className="hero-header78">
      <div className="hero-column thq-section-padding thq-section-max-width hero-bg" style={{ backgroundImage: `url(${cross})`}}>
        
        <div className="hero-content">
          <h2 className="hero-text thq-heading-1">{props.heading1}<span className='thq-body-small'>  వారు నిర్వహించు</span></h2>
          <h1 className='hero-text thq-heading-4'>ఆదివారం ఆరాధన</h1>
          <p className="hero-text1 thq-body-large">{props.content1}</p>
        </div>
        
        
      </div>
      <div className="hero-content1">
        <div className="hero-row-container thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">

            {props.images.map((img)=> <img
            key={img}
              alt={'Hero image'}
              src={img}
              className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />)}
            
          </div>
          <div className="thq-animated-group-horizontal">
          {props.images.map((img)=> <img
          key={img}
              alt={'Hero image'}
              src={img}
              className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />)}
          </div>
        </div>
        {/* <div className="hero-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
          {props.images.map((img)=> <img
              alt={'Hero image'}
              key={img}
              src={img}
              className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />)}
          </div>
          <div className="thq-animated-group-horizontal-reverse">
          {props.images.map((img)=> <img
              alt={'Hero image'}
              key={img}
              src={img}
              className="hero-placeholder-image thq-img-scale thq-img-ratio-1-1"
            />)}
          </div>
        </div> */}
      </div>
      <div>
        <div className="hero-container1">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {
  
  images:[img1,img2,img3,img4,img5,img6,img7,img8,img9,img10 ],
  
  image3Src:    'https://images.unsplash.com/photo-1465848059293-208e11dfea17?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0NHw&ixlib=rb-4.0.3&q=80&w=1080',
  image8Alt: 'Hero Image',
  image2Src:"p1",
  image6Alt: 'Hero Image',
  image11Src:
    'https://images.unsplash.com/photo-1629732902719-b9b232037b7b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  image5Alt: 'Hero Image',
  image1Alt: 'Hero Image',
  image7Src:
    'https://images.unsplash.com/photo-1526825250684-7482c1c58de7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image7Alt: 'Hero Image',
  image12Alt: 'Hero Image',
  image2Alt: 'Hero Image',
  image6Src:
    'https://images.unsplash.com/photo-1476214211866-f4af317fc977?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image12Src:
    'https://images.unsplash.com/photo-1485548125564-21b2276a644a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image3Alt: 'Hero Image',
  image9Src:
    'https://images.unsplash.com/photo-1541791940715-e571aa1c3d32?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image11Alt: 'Hero Image',
  action2: 'Secondary action',
  action1: 'Main action',
  image8Src:
    'https://images.unsplash.com/photo-1461829576572-c5fc8ed06ae9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  image5Src:
    'https://images.unsplash.com/photo-1564819092380-3339c393cbac?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OXw&ixlib=rb-4.0.3&q=80&w=1080',
  image4Src:
    'https://images.unsplash.com/photo-1510590124886-dc2653b48bf0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OXw&ixlib=rb-4.0.3&q=80&w=1080',
  image10Alt: 'Hero Image',
  image4Alt: 'Hero Image',
  heading1: 'క్రీస్తు సంఘము',
  content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  image10Src:
    'https://images.unsplash.com/photo-1515962510676-edb6a741708a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY0OXw&ixlib=rb-4.0.3&q=80&w=1080',
  image9Alt: 'Hero Image',
  image1Src:
    'https://images.unsplash.com/photo-1543702404-38c2035462ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxOTgyNTY1MHw&ixlib=rb-4.0.3&q=80&w=1080',
}

Hero.propTypes = {
  image3Src: PropTypes.string,
  image8Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image11Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image12Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image12Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image9Src: PropTypes.string,
  image11Alt: PropTypes.string,
  action2: PropTypes.string,
  action1: PropTypes.string,
  image8Src: PropTypes.string,
  image5Src: PropTypes.string,
  image4Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  image10Src: PropTypes.string,
  image9Alt: PropTypes.string,
  image1Src: PropTypes.string,
}

export default Hero
