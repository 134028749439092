import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar1'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Pricing from '../components/pricing'
import Steps from '../components/steps'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'
import Team11 from '../components/team11'


const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Spotless Hungry Crocodile</title>
      </Helmet>
      <Navbar></Navbar>
      <Hero ></Hero>
      <Features1></Features1>
      <Team11></Team11>
      <CTA></CTA>
      <Features2></Features2>
      {/* <Pricing></Pricing> */}
      <Steps></Steps>
      <Contact></Contact>
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Home
